import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  color: #DE3737;
  background-color: rgba(255, 225, 225, 0.85);
  border: 1px solid #DF5555;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 20px;
  white-space: pre-wrap;
  font-weight: 500;
`;

const ErrorBox = ({infoText,children}) => {
    return (
        <Wrapper className={'errorStyle'}>
            {infoText}
            {children}
        </Wrapper>
    );
};

export default ErrorBox;